<template>
  <div id="admin-classification" class="admin-contents">
    <div class="admin-contents-title">分类、标签管理</div>
    <div class="admin-contents-wrap admin-classification-wrap">
      <el-tabs v-model="activeName">
        <el-tab-pane name="category">
          <span slot="label">
            <i class="el-icon-myblog-folder"></i> 分类管理
          </span>
          <div class="classification-selector">
            <el-input
              class="classification-title-query"
              size="medium"
              placeholder="分类名"
              v-model="categoryName"
              clearable>
            </el-input>
            <el-button
              size="medium"
              type="primary"
              @click="queryCategory"
              icon="el-icon-search">
              查询分类
            </el-button>
          </div>
          <el-table
            :data="categories"
            border>
            <el-table-column
              prop="name"
              label="分类名"
              width="180">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEditCategory(scope.row)">编辑</el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDeleteCategory(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="tag">
          <span slot="label">
            <i class="el-icon-myblog-discount"></i> 标签管理
          </span>
          <div class="classification-selector">
            <el-input
              class="classification-title-query"
              size="medium"
              placeholder="标签名"
              v-model="tagName"
              clearable>
            </el-input>
            <el-button
              size="medium"
              type="primary"
              @click="queryTag"
              icon="el-icon-search">
              查询标签
            </el-button>
          </div>
          <el-table
            :data="tags"
            border>
            <el-table-column
              prop="name"
              label="标签名"
              width="180">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEditTag(scope.row)">编辑</el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDeleteTag(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      :title="modify.title + ' - ' + modify.formerName"
      :visible.sync="dialogVisible"
      @close="dialogClose">
      <el-input
        size="medium"
        :placeholder="modify.formerName"
        v-model="modify.newName"
        clearable>
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'admin-classification',
  data () {
    return {
      activeName: 'category',
      categoryName: '',
      tagName: '',
      categories: [],
      tags: [],
      modify: { id: '', formerName: '', newName: '', type: 1, title: '修改分类' },
      dialogVisible: false
    }
  },
  methods: {
    dialogClose () {
      this.modify = {
        id: '',
        formerName: '',
        newName: '',
        type: 1,
        title: '修改分类'
      }
    },
    dialogSubmit () {
      const request = {
        // 分类
        1: {
          url: this.$api.ADMIN_CATEGORIES_URL,
          method: 'PUT',
          data: {
            categoryId: this.modify.id,
            newName: this.modify.newName
          }
        },
        // 标签
        2: {
          url: this.$api.ADMIN_TAGS_URL,
          method: 'PUT',
          data: {
            tagId: this.modify.id,
            newName: this.modify.newName
          }
        }
      }
      this.$auth_http(request[this.modify.type]).then(response => {
        if (response.status === 'success') {
          this.$message.success('保存成功')
          if (this.modify.type === 1) this.queryCategory()
          else this.queryTag()
          this.dialogVisible = false
        } else {
          this.$message.error('保存失败' + response.message)
        }
      }).catch(error => {
        this.$message.error('保存失败' + error.toString() + '，请重试')
      })
    },
    queryCategory () {
      this.categories = []
      this.$auth_http({
        url: this.$api.ADMIN_CATEGORIES_URL,
        method: 'GET',
        params: {
          categoryName: this.categoryName
        }
      }).then(response => {
        for (const item of response.data.result) {
          this.categories.push(item)
        }
      }).catch(error => {
        this.$message.error('获取分类失败：' + error.toString() + '，请重试')
      })
    },
    queryTag () {
      this.tags = []
      this.$auth_http({
        url: this.$api.ADMIN_TAGS_URL,
        method: 'GET',
        params: {
          tagName: this.tagName
        }
      }).then(response => {
        for (const item of response.data.result) {
          this.tags.push(item)
        }
      }).catch(error => {
        this.$message.error('获取标签失败：' + error.toString() + '，请重试')
      })
    },
    handleEditCategory (row) {
      this.modify = {
        id: row.categoryId,
        formerName: row.name,
        newName: '',
        type: 1,
        title: '修改分类'
      }
      this.dialogVisible = true
    },
    handleEditTag (row) {
      this.modify = {
        id: row.tagId,
        formerName: row.name,
        newName: '',
        type: 2,
        title: '修改标签'
      }
      this.dialogVisible = true
    },
    handleDeleteCategory (row) {
      this.$auth_http({
        url: this.$api.ADMIN_CATEGORIES_URL,
        method: 'DELETE',
        params: {
          categoryId: row.categoryId
        }
      }).then(response => {
        if (response.status === 'success') {
          this.$message.success('删除分类成功')
          this.queryCategory()
        } else {
          this.$message.error('删除分类失败' + response.message)
        }
      }).catch(error => {
        this.$message.error('删除分类失败：' + error.toString() + '，请重试')
      })
    },
    handleDeleteTag (row) {
      this.$auth_http({
        url: this.$api.ADMIN_TAGS_URL,
        method: 'DELETE',
        params: {
          tagId: row.tagId
        }
      }).then(response => {
        if (response.status === 'success') {
          this.$message.success('删除标签成功')
          this.queryTag()
        } else {
          this.$message.error('删除标签失败' + response.message)
        }
      }).catch(error => {
        this.$message.error('删除标签失败：' + error.toString() + '，请重试')
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
#admin-classification
  .admin-classification-wrap
    .classification-selector
      margin-bottom: 20px
      display: inline-flex
      .classification-title-query
        margin-right: 10px
    .el-table
      max-width: 500px
</style>
